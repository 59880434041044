const serviceArray = [
    {
        title: "ケアサポートツマダ",
        label: "訪問介護",
        image: 'service01',
        text: [
            "私たち訪問介護員は、ご利用者様やそのご家族と個別で深い関りを持ちながら、その方らしい生活の実現をサポートでき、介護職として大きなやりがいを持って活動をしています。",
            "人と人との関わりを大切に、まごころとやさしさをこの先もお届けしたいと思います。20代から70代の幅広い年齢層や多くの男性ヘルパーの活躍もあり、様々な視点で自分たちの意見等を出し合いながら同じ目標に向けたチームケアを心掛けています。",
            ],
        businessDay: "月曜日～金曜日（ただしヘルパー活動は月曜日～日曜日）",
        businessHours: "8:30～17:30（電話等により24時間ご連絡頂けます）（ヘルパー活動はご相談により24時間対応します）",
        holiday: "日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-221-9448",
        address: "〒243-0813 厚木市妻田東1-23-6 三和ビル202",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d406.23155289884903!2d139.3622531419612!3d35.45844781123202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019000327c717cf%3A0x913d29af1b581166!2z44Kx44Ki44K144Od44O844OI44O744OE44Oe44OA!5e0!3m2!1sja!2sjp!4v1683013720435!5m2!1sja!2sjp",
    },
    {
        title: "レスパイトセンター<br class='sp_br'>どんぐり",
        label: "日中一時支援",
        image: 'service02',
        text: [
            "ご利用者様にとって安心できる日中活動の場を確保しつつ、介護をされているご家族の就労支援や、休息を取って頂くことで家族が良い関係で過ごすお手伝い、それが私たちの果たす役目です。",
            "就学前から高校卒業までの心と体の大切な成長時期を育む第二のマイホームとして、温かく優しい思いで丁寧に関わらせて頂いております。",
            ],
        businessDay: "月曜日～土曜日 祝祭日も開所",
        UtilizationTime: "14:00～17:00（利用時間に関しては相談に応じます）<br>学校休業日の場合は11:00～17:00",
        holiday: "日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-295-6188",
        address: "厚木市妻田東1-23-6 三和ビル1F201",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d406.23155289884903!2d139.3622531419612!3d35.45844781123202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019000327c717cf%3A0x77ac158398d76a88!2z44Gp44KT44GQ44KK!5e0!3m2!1sja!2sjp!4v1683013774809!5m2!1sja!2sjp",
    },
    {
        title: "ケアサポート今泉",
        label: "訪問介護",
        image: 'service03',
        text: [
            "一番大切にしている事は、ご利用者様との信頼関係です。<br>当然と言えば当然ですが、心の通う「来てもらって良かった」と喜んで頂く事が全職員の目標であり、モチベーションに繋がります。",
            "住み慣れた我が家に一日でも長く、穏やかな日々がおくれるよう全力で支援して参ります。",
            ],
        businessDay: "月曜日～金曜日・祝祭日(サービスは月曜日~土曜日)",
        businessHours: "8:30～17:30（電話等により24時間ご連絡頂けます）",
        holiday: "土・日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-204-8883",
        address: "上今泉2-10-8 コーポPB-5 101号",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d812.3827123671634!2d139.39667242853653!3d35.46640749055468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ff864b9ee7eb%3A0x1beb8aff43ad0ea5!2z44CSMjQzLTA0MzEg56We5aWI5bed55yM5rW36ICB5ZCN5biC5LiK5LuK5rOJ77yS5LiB55uu77yR77yQ4oiS77yYIOOCs-ODvOODne-8sO-8ou-8je-8lSAxMDE!5e0!3m2!1sja!2sjp!4v1683013816583!5m2!1sja!2sjp",
    },
    {
        title: "ケアサポート上依知",
        label: "訪問介護",
        image: 'service04',
        text: [
            "厚木市上依知に位置する当事業所は相模川を渡れば相模原市、北側に進めば愛川町、圏央道を使えば都心まで1時間という立地で緑豊かで美しい川に恵まれた街です。",
            "ご利用者様にプロ介護技術をお届けしようとプチ研修会を度々開催してスキルアップを実践し、今日も困っている方の元へ出動します。",
            ],
        businessDay: "月曜日～金曜日・祝祭日",
        businessHours: "8:30～17:30（電話等により24時間ご連絡頂けます）",
        holiday: "日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-280-5644",
        address: "〒243-0801 厚木市上依知1594-1",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.97633224452125!2d139.36011810697119!3d35.513640847339886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601901fb57e01793%3A0x97939ca7f9107d0e!2z44CSMjQzLTA4MDEg56We5aWI5bed55yM5Y6a5pyo5biC5LiK5L6d55-l77yR77yV77yZ77yU4oiS77yR!5e0!3m2!1sja!2sjp!4v1683013861978!5m2!1sja!2sjp",
    },
    {
        title: "デイサービスくぬぎ",
        label: "訪問介護",
        image: 'service05',
        text: [
            "アットホームなデイサービスです。<br>一度おいで頂けば、また利用したくなるポイントが3つ‼",
            "1.食事が何しろ美味しい。郷土料理、季節の食材、地産地消にこだわった手作りメニューを提供しています。<br>『食べる事は生きる事！楽しい事！』がスローガンです☆",
            "2.広いお風呂を独り占め ゆったりのんびり入浴出来て温泉気分を満喫できます。ご希望があればバラの花も浮かせます。",
            "3.職員は皆あなたの家族 息子や娘や孫のように温かい気持ちで親身になってお手伝いします。くぬぎに関わる皆が「ワンファミリー」",
            ],
        businessDay: "月曜日～土曜日・祝祭日",
        UtilizationTime: "9:15～16:25",
        holiday: "日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-296-4557",
        address: "〒243-0812 厚木市妻田北3-18-3",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.4914729701027!2d139.3547055118008!3d35.46738204157695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60190022f373deff%3A0xad391c507bcb4b57!2z44CSMjQzLTA4MTIg56We5aWI5bed55yM5Y6a5pyo5biC5aa755Sw5YyX77yT5LiB55uu77yR77yY4oiS77yTIOODh-OCpOOCteODvOODk-OCueOBj-OBrOOBjg!5e0!3m2!1sja!2sjp!4v1683013884095!5m2!1sja!2sjp",
    },
    {
        title: "ケアサポートにこにこ",
        label: "日中一時支援",
        image: 'service06',
        text: [
            "のびのびフロアーでは、体を動かし思い切り遊べるスペースがあります。車椅子をご利用しているご利用者様にも優しいバリアフリーです。個人のやりたい事を優先に、それぞれが望む日中活動のお手伝いしています。ボール遊びやカードゲーム、折り紙などを使った創作も楽しんで行っています。",
            "職員間の情報共有は常にアップデートされており、特に行動や心理的な支援を大切にしています。急用時も柔軟に追加利用もお受けしています。困った時こそ「にこにこ」にお任せください。",
            ],
        businessDay: "月曜日～土曜日・祝祭日",
        UtilizationTime: "14:00～17:00（利用時間に関しては相談に応じます）<br>学校休業日の場合は11:00～17:00",
        holiday: "日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-231-8733",
        address: "〒243-0431 海老名市上今泉2-11-40 サーパス海老名108号",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.487836494629!2d139.39498651180088!3d35.46747204157209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ff86429b1f2d%3A0xbf697e673adbf308!2z44CSMjQzLTA0MzEg56We5aWI5bed55yM5rW36ICB5ZCN5biC5LiK5LuK5rOJ77yS5LiB55uu77yR77yR4oiS77yU77yQIOOCteODvOODkeOCuea1t-iAgeWQjSAxMDg!5e0!3m2!1sja!2sjp!4v1683013920603!5m2!1sja!2sjp",
    },
    {
        title: "生活介護SORA",
        label: "生活介護",
        image: 'service07',
        text: [
            "SORAに通う多くの方々は青春時代をここで過ごします。<br>建物のコンセプトは町中散策。目を引く装飾やカラフルな色使いで、カフェや図書館にふらっと入ってゆったりとした良い時間を過ごす。仲間を増やし、色々な人と触れ合う経験を積み、日常生活に必要な力を獲得する事や苦手なことを克服できる力を養い、自分の好きなことや得意なことを気づく。",
            "人生のひと時を共にする者として、最大限のお手伝いでお役に立ちたいと考えます。そして最高のケアパートナーになりたいです。",
            ],
        businessDay: "月曜日～金曜日・祝祭日",
        UtilizationTime: "9:30〜16:00（送迎時間を除く）",
        holiday: "土曜日・日曜日<br>【夏季休業】8/13～8/15【年末年始】12/30～1/3",
        phone: "046-204-9907",
        address: "〒243-0431 海老名市上今泉2-11-40 サーパス海老名108号",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.487836494629!2d139.39498651180088!3d35.46747204157209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ff86429b1f2d%3A0xbf697e673adbf308!2z44CSMjQzLTA0MzEg56We5aWI5bed55yM5rW36ICB5ZCN5biC5LiK5LuK5rOJ77yS5LiB55uu77yR77yR4oiS77yU77yQIOOCteODvOODkeOCuea1t-iAgeWQjSAxMDg!5e0!3m2!1sja!2sjp!4v1683013948684!5m2!1sja!2sjp",
    },
];
const service = document.querySelector(".service");
if (service) {
    const serviceContentInner = document.querySelector(".service-content__inner");
    const serviceAnchorList = document.querySelector(".service-anchor__list");
    serviceArray.forEach((service, index) => {

        const serviceAnchor = `
        <li class="service-anchor__item">
            <a href="#${service.image}" class="service-anchor__link">${service.title}</a>
        </li>
        `;
        serviceAnchorList.insertAdjacentHTML("beforeend", serviceAnchor);


        let serviceContentText = "";
        service.text.forEach((text, index) => {
            const serviceText = `<p class="service-content__text">${text}</p>`;
            serviceContentText += serviceText;
        });

        let timeItem = "";
        if (service.businessHours !== undefined) {
            timeItem = `
            <div class="service-content__item">
                <dt class="service-content__item-title">営業時間</dt>
                <dd class="service-content__item-text">${service.businessHours}</dd>
            </div>
            `;
        } else {
            timeItem = `
            <div class="service-content__item">
                <dt class="service-content__item-title">利用時間</dt>
                <dd class="service-content__item-text">${service.UtilizationTime}</dd>
            </div>
            `;
        }

        const serviceItem = `
        <section class="service-content__section" id="${service.image}">
            <h2 class="service-content__title"><span>${service.label}</span>${service.title}</h2>
            <div class="service-content__flex">
                <picture class="service-content__image">
                    <source type='image/webp' srcset='/sc-cms/wp-content/themes/sc-planning/assets/webp/service/${service.image}.webp'>
                    <img src="/sc-cms/wp-content/themes/sc-planning/assets/images/service/${service.image}.png" alt="">
                </picture>
                <div class="service-content__flex-inner">
                    <h3 class="service-content__subtitle">サービス案内</h3>
                    ${serviceContentText}
                </div>
            </div>
            <dl class="service-content__list">
                <div class="service-content__item">
                    <dt class="service-content__item-title">営業日</dt>
                    <dd class="service-content__item-text">${service.businessDay}</dd>
                </div>
                ${timeItem}
                <div class="service-content__item">
                    <dt class="service-content__item-title">休日</dt>
                    <dd class="service-content__item-text">${service.holiday}</dd>
                </div>
                <div class="service-content__item">
                    <dt class="service-content__item-title">電話番号</dt>
                    <dd class="service-content__item-text"><a class="service-content__item-text--tel" href='tel:${service.phone}'>${service.phone}</a></dd>
                </div>
                <div class="service-content__item">
                    <dt class="service-content__item-title">住所</dt>
                    <dd class="service-content__item-text">${service.address}</dd>
                </div>
            </dl>
            <div class="service-content__img">
                <iframe src="${service.map}" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
        `;
        serviceContentInner.insertAdjacentHTML("beforeend", serviceItem);
    });
}
